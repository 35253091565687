import { defineStore } from "pinia";

export const useAppStore = defineStore('app', {
  state: () => ({
    loading: false,
    online: true,
    organization: {
      _id: null,
      name: null,
      active: false,
      clusterSecretKey: null,
      stats: {
        total: null,
      },
      settings: {
        secureLinkDisabled: false,
      },
      deletedAt: null,
    },
    clusterViews: [
      {
        name: 'All Clusters',
        id: 'all',
        filter: null,
        system: true,
      },
    ],
  }),
  actions: {
    logout() {
      this.organization = {
        id: null,
        name: null,
        settings: null
      };
    },
    setOrganizationId(organizationId){
      this.organization._id = organizationId;
    },
    setOrganization(organization) {
      this.organization = {
        _id: organization._id,
        name: organization.name,
        active: organization.active,
        stats: organization.stats,
        clusterSecretKey: organization.clusterSecretKey,
        settings: organization.settings,
        deletedAt: organization.deletedAt
      }
    },
    addClusterView(clusterView) {
      this.clusterViews.push(clusterView);
    },
    removeClusterView(clusterView) {
      var index = this.clusterViews
        .map((x) => {
          return x.id;
        })
        .indexOf(clusterView.id);
      this.clusterViews.splice(index, 1);
    },
    setLoading(loadingState) {
      this.loading = loadingState;
    },
    setOnline(onlineState) {
      this.online = onlineState;
    },
  },
  getters: {
    onlineStatus(state) {
      return state.online;
    },
    org: (state) => {
        return state.organization;
    },
  },
  persist: true,
});
