import api from './api';
import api2 from './api-v2';

const filter = async ({ filters }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetClusters({ filters: JSON.stringify(filters) }));
};

const filter2 = async (filters) => {
    const client = await api2.getClient();
    const clusters = await client.get('clusters', { params: filters });
    clusters.data.items.map(convertClusterObjects);
    return api.handleSuccess(clusters);
};

const getById = async ({ id }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetCluster({ clusterId: id }));
};

const getById2 = async ({ id }) => {
    const client = await api2.getClient();
    const cluster = await client.get(`clusters/${id}`);
    convertClusterObjects(cluster.data);
    return api.handleSuccess(cluster);
};

const removeById = async ({ id }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.DeleteCluster({ clusterId: id }));
};

const commandSend = async ({ id, command, payload }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.ClusterCommandSend({ clusterId: id }, { command, payload }));
};

const create = async ({ payload }) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.CreateCluster(null, payload));
};

const upsert = async ({ id, payload }) => {
    const client = await api2.getClient();
    return await api2.handleSuccess(await client.post(`clusters/${id}/tags`, payload));
};

const updateTags = async ({ id, tags }) => {
    const client = await api2.getClient();
    return await api2.handleSuccess(await client.post(`clusters/${id}/tags`, { tags: tags }));
};

const getConfig = async ({ clusterId }) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.GetClusterConfig({ clusterId: clusterId }));
};

const startTunnel = async ({ clusterId, additionalRouteArgs }) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.StartTunnel({ clusterId: clusterId }, { additionalRouteArgs }));
};

const updateCluster = async ({ clusterId, update }) => {
    const client = await api.getClient();
    return api.handleSuccess(await client.UpdateCluster({ clusterId }, update));
};

const convertClusterObjects = (e) => {
    if (e.tags) {
        e.tags = e.tags.split(',');
    }
    return e;
};

export default {
    filter,
    getById,
    getById2,
    removeById,
    commandSend,
    create,
    getConfig,
    startTunnel,
    updateCluster,
    filter2,
    upsert,
    updateTags,
};
