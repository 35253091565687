import { firebase } from '../firebase';
import { user } from '../nucleus';
import { getAppStore, getUserStore } from '../../store';
import { deleteJwtCookie } from '../cookies';


const customErrorMessages = {
    'auth/user-not-found': 'Login invalid or account does not exist',
    'auth/wrong-password': 'Login invalid or account does not exist',
};

const setPersistance = async () => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
};

const _authenticateUser = async (provider) => {
    const authenticatedUser = await user.authenticateUser();
    //refresh firebase token to ensure we have the latest claims
    await firebase.auth().currentUser.getIdToken(true);
    await user.updateProvider(provider);
    
    return authenticatedUser.data;
};

const createAccountWithEmailAndPassword = async (email, password, token) => {
    try {
        if(!token){
            throw new Error('Missing invite token, please open this page directly from the link in your email');
        }
        
        let tokenIsValid = await user.verifyInviteToken(email, token);
  
        if(tokenIsValid.data.data.valid === true){
            await firebase.auth().createUserWithEmailAndPassword(email, password);

            const authenticatedUser = await _authenticateUser('password');
            return authenticatedUser;
        }
        else{
               throw new Error('The email and token combination is not valid. Please request a new invite email from the account owner');
        }
       
    } catch (e) {
        let msg = '';
        if (customErrorMessages[e.code]) {
            msg = customErrorMessages[e.code];
        } else {
            msg = e.message;
        }
        throw new Error(msg);
    }
};

const loginWithEmailAndPassword = async (email, password) => {
    try {
        await setPersistance();
        await firebase.auth().signInWithEmailAndPassword(email, password);
        // authenticate user against nucleus
        const authenticatedUser = await _authenticateUser('password');
        return authenticatedUser;
    } catch (e) {
        let msg = '';
        if (customErrorMessages[e.code]) {
            msg = customErrorMessages[e.code];
        } else {
            msg = e.message;
        }
        throw new Error(msg);
    }
};

const loginWithCustomToken = async (token, provider) => {
    try {
        await firebase.auth().signInWithCustomToken(token);
        return await _authenticateUser(provider);
    } catch (e) {
        throw new Error(`Unable to login with custom token, ${JSON.stringify(e)}`);
    }
}

const loginWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    let msg = '';
    try {
        await setPersistance();
        const result = await firebase.auth().signInWithPopup(provider);
        return result;
    } catch (e) {
        if (customErrorMessages[e.code]) {
            msg = customErrorMessages[e.code];
        } else {
            msg = e.message;
        }
        throw new Error(msg);
    }
};

const loginWithMicrosoft = async () => {
    var provider = new firebase.auth.OAuthProvider('microsoft.com');
    let msg = '';
    try {
        await setPersistance();
        const result = await firebase.auth().signInWithPopup(provider);
        return result;
    } catch (e) {
        if (customErrorMessages[e.code]) {
            msg = customErrorMessages[e.code];
        } else {
            msg = e.message;
        }
        throw new Error(msg);
    }
};

const loginWithProvider = async (provider) => {
    if (provider === 'google.com') {
        await loginWithGoogle();
    } else if (provider === 'microsoft.com') {
        await loginWithMicrosoft();
    } else {
        throw new Error(`Authentication provider ${provider} does not exist.`);
    }

    // update the logged in user in the nucleus db.
    const authenticatedUser = await _authenticateUser(provider);
    return authenticatedUser;
};

const sendPasswordResetEmail = async (email) => {
    try {
        await user.sendPasswordResetEmail(email);
    } catch (e) {
        throw new Error(e.message);
    }
};

const resetPassword = async (email, code, password) => {
    try {
        await user.resetPassword(email, code, password);
    } catch (e) {
        throw new Error(e.message);
    }
};

const logout = async () => {
    const appStore = getAppStore();
    deleteJwtCookie();
    await firebase.auth().signOut();
    appStore.logout();
    return true;
};

const isLoggedIn = () => {
    const userStore = getUserStore();
    return userStore?.loggedIn || false;
};

export default {
    createAccountWithEmailAndPassword,
    loginWithEmailAndPassword,
    loginWithCustomToken,
    loginWithProvider,
    sendPasswordResetEmail,
    resetPassword,
    logout,
    isLoggedIn,
};
